// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2017, 2022
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.
import PropTypes from 'prop-types'
import computeBasePath from 'Uif/apps/computeBasePath'
import {hasAPICapability, hasEventsCapability} from '../lib/cp4i'

const configTypes = {
  atmBasePath: PropTypes.string,
  displayGlobalHeader: PropTypes.bool,
  enableLoginFlow: PropTypes.bool,
  enableProviderOrgMemberInvite: PropTypes.bool,
  formFactor: PropTypes.oneOf([
    'designer',
    'ibm-cloud',
    'icp4i',
    'aws',
    'on-prem',
  ]),
  basePath: PropTypes.string,
  namespace: PropTypes.string,
  instance: PropTypes.string,
  telemetryEnabled: PropTypes.bool,
  segmentUrl: PropTypes.string,
  segmentKey: PropTypes.string,
  segmentUrlProd: PropTypes.string,
  segmentKeyProd: PropTypes.string,
  apiCapabilityEnabled: PropTypes.bool,
  eventsCapabilityEnabled: PropTypes.bool,
  shouldInjectZenHeader: PropTypes.bool,
  apicUiFeatureFlags: PropTypes.string, // urlencoded string: flagId=true&flagId2=always
}

const configDefaults = {
  atmBasePath: undefined,
  displayGlobalHeader: true,
  enableLoginFlow: true,
  enableProviderOrgMemberInvite: true,
  formFactor: 'on-prem',
  basePath: '',
  namespace: '',
  instance: '',
  telemetryEnabled: false,
  segmentUrl: '',
  segmentKey: '',
  apiCapabilityEnabled: true,
  eventsCapabilityEnabled: false,
  shouldInjectZenHeader: false,
  licenseType: 'VIRTUAL_PROCESSOR_CORE',
  apicUiFeatureFlags: '',
  enableAnalyticsForEvents: false,
}

export default function getConfig(app) {
  const requestedConfig = window.apiConnectCfg || {
    apiCapabilityEnabled: hasAPICapability(),
    eventsCapabilityEnabled: hasEventsCapability(),
  }

  // set basePath. basePath is non empty string in zen env
  configDefaults.basePath = `${computeBasePath()}`

  PropTypes.checkPropTypes(
    configTypes,
    requestedConfig,
    'prop',
    'configOptions'
  )

  return {
    ...configDefaults,
    ...requestedConfig,
  }
}
