// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2019, 2022
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.
import {parse as urlParse} from 'url'
const EMPTY_BASE_PATH = ''

export default function computeBasePath() {
  if (
    window.apiConnectCfg &&
    window.apiConnectCfg.formFactor === 'ibm-cloud' &&
    typeof window.apiConnectCfg.basePath === 'undefined'
  ) {
    return EMPTY_BASE_PATH
  }
  if (
    window.apiConnectCfg &&
    (window.apiConnectCfg.formFactor === 'icp4i' ||
      window.apiConnectCfg.formFactor === 'ibm-cloud')
  ) {
    return window.apiConnectCfg.basePath
  }

  const baseElements = document.getElementsByTagName('base')
  const hasElements = baseElements.length > 0
  const rawBasePath = hasElements && baseElements[0].href
  if (rawBasePath) {
    return urlParse(rawBasePath).pathname
  }

  return EMPTY_BASE_PATH
}
