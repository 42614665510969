// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2020
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.
/* global __webpack_public_path__:true */
import configOptions from 'Apps/configOptions'

const config = configOptions()
if (config.basePath) {
  __webpack_public_path__ = `${config.basePath}/`
}
