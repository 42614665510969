// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2020, 2021
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

import _ from 'lodash'

const checkForURLOverride = overrideKey => {
  const currentURL = new URL(window.location)
  return currentURL.searchParams.has(overrideKey)
    ? currentURL.searchParams.get(overrideKey) === 'true'
    : false
}

export function isCp4iFormFactor() {
  return _.get(window, 'apiConnectCfg.formFactor') === 'icp4i'
}

export function isCloudFormFactor() {
  return _.get(window, 'apiConnectCfg.formFactor') === 'ibm-cloud'
}

export function hasAPICapability() {
  return (
    _.get(window, 'apiConnectCfg.apiCapabilityEnabled', true) ||
    checkForURLOverride('api_capability')
  )
}

export function hasEventsCapability() {
  return (
    _.get(window, 'apiConnectCfg.eventsCapabilityEnabled', false) ||
    checkForURLOverride('event_capability')
  )
}

export function isUsageBased() {
  return (
    _.get(window, 'apiConnectCfg.licenseType', 'VIRTUAL_PROCESSOR_CORE') ===
      'MONTHLY_API_CALL' || checkForURLOverride('usage_based')
  )
}

export function canAssociateEventGatewayWithAnalytics() {
  return (
    _.get(window, 'apiConnectCfg.enableAnalyticsForEvents', false) ||
    checkForURLOverride('enable_analytics_for_events')
  )
}

export function filterDocumentByCapability(document) {
  // omnisearch overwrites api_type & places the original value in api_type_id,
  // so use api_type_id if it exists and api_type otherwise
  const api_type = document.api_type_id || document.api_type
  return (
    !api_type ||
    api_type === 'product' ||
    (api_type === 'asyncapi' && hasEventsCapability()) ||
    (api_type !== 'asyncapi' && hasAPICapability())
  )
}

export function filterAPIResultsByCapability(apis) {
  const {total_results: originalTotalResults, results: originalResults} = apis

  // Filter the results by capability
  const results = originalResults.filter(filterDocumentByCapability)
  // Adjust total_results now that some documents may be filtered out
  const total_results =
    originalTotalResults - (originalResults.length - results.length)
  return {
    results,
    total_results,
  }
}
